.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 2px;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #333;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  box-shadow: var(--bs-dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 468px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1024px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1600px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.dropdown-toggle::after, .dropup .dropdown-toggle::after {
  vertical-align: 0.255em;
  content: "";
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  display: inline-block;
}

.dropdown-toggle:empty::after, .dropend .dropdown-toggle:empty::after, .dropstart .dropdown-toggle:empty::after, .dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-header, .dropdown-item, .dropdown-toggle {
  white-space: nowrap;
}

.dropdown, .dropdown-center, .dropend, .dropstart, .dropup, .dropup-center {
  position: relative;
}

.dropdown-toggle::after {
  margin-left: 0.255em;
  border-top: 0.3em solid;
  border-bottom: 0;
}

.dropdown-menu {
  --anw-dropdown-min-width:10rem;
  --anw-dropdown-padding-x:0;
  --anw-dropdown-padding-y:0.5rem;
  --anw-dropdown-spacer:0.125rem;
  --anw-dropdown-font-size:1rem;
  --anw-dropdown-color:#333;
  --anw-dropdown-bg:#fff;
  --anw-dropdown-border-color:var(--anw-border-color-translucent);
  --anw-dropdown-border-radius:2px;
  --anw-dropdown-border-width:1px;
  --anw-dropdown-inner-border-radius:1px;
  --anw-dropdown-divider-bg:var(--anw-border-color-translucent);
  --anw-dropdown-divider-margin-y:0.5rem;
  --anw-dropdown-box-shadow:0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
  --anw-dropdown-link-color:#333;
  --anw-dropdown-link-hover-color:#2e2e2e;
  --anw-dropdown-link-hover-bg:#f3f3f3;
  --anw-dropdown-link-active-color:#fff;
  --anw-dropdown-link-active-bg:#385273;
  --anw-dropdown-link-disabled-color:#a3a3a3;
  --anw-dropdown-item-padding-x:1rem;
  --anw-dropdown-item-padding-y:0.25rem;
  --anw-dropdown-header-color:#333;
  --anw-dropdown-header-padding-x:1rem;
  --anw-dropdown-header-padding-y:0.5rem;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: var(--anw-dropdown-min-width);
  padding: var(--anw-dropdown-padding-y) var(--anw-dropdown-padding-x);
  margin: 0;
  font-size: var(--anw-dropdown-font-size);
  color: var(--anw-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--anw-dropdown-bg);
  background-clip: padding-box;
  border: var(--anw-dropdown-border-width) solid var(--anw-dropdown-border-color);
  border-radius: var(--anw-dropdown-border-radius);
  box-shadow: var(--anw-dropdown-box-shadow);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--anw-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position:start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position:end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 468px) {
  .dropdown-menu-sm-start {
    --bs-position:start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position:end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position:start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position:end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1024px) {
  .dropdown-menu-lg-start {
    --bs-position:start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position:end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position:start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position:end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1600px) {
  .dropdown-menu-xxl-start {
    --bs-position:start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position:end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--anw-dropdown-spacer);
}

.dropup .dropdown-toggle::after {
  margin-left: 0.255em;
  border-top: 0;
  border-bottom: 0.3em solid;
}

.dropend .dropdown-toggle::after, .dropstart .dropdown-toggle::before {
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  content: "";
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--anw-dropdown-spacer);
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  border-right: 0;
  border-left: 0.3em solid;
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--anw-dropdown-spacer);
}

.dropstart .dropdown-toggle::after {
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  border-right: 0.3em solid;
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--anw-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--anw-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--anw-dropdown-item-padding-y) var(--anw-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--anw-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  background-color: transparent;
  border: 0;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: var(--anw-dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--anw-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--anw-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--anw-dropdown-link-active-bg);
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--anw-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--anw-dropdown-header-padding-y) var(--anw-dropdown-header-padding-x);
  margin-bottom: 0;
  color: var(--anw-dropdown-header-color);
  font-size: 1rem;
  font-weight: 600;
}

.dropdown-item-text {
  display: block;
  padding: var(--anw-dropdown-item-padding-y) var(--anw-dropdown-item-padding-x);
  color: var(--anw-dropdown-link-color);
}

.dropdown-menu-dark {
  --anw-dropdown-color:#f0f0f0;
  --anw-dropdown-bg:#5c5c5c;
  --anw-dropdown-border-color:var(--anw-border-color-translucent);
  --anw-dropdown-link-color:#f0f0f0;
  --anw-dropdown-link-hover-color:#fff;
  --anw-dropdown-divider-bg:var(--anw-border-color-translucent);
  --anw-dropdown-link-hover-bg:rgba(255, 255, 255, 0.15);
  --anw-dropdown-link-active-color:#fff;
  --anw-dropdown-link-active-bg:#385273;
  --anw-dropdown-link-disabled-color:#a3a3a3;
  --anw-dropdown-header-color:#a3a3a3;
}

.dropdown-menu {
  margin-top: 0.5rem;
}

.dropdown-menu .popover-arrow {
  display: block;
  height: 0.5rem;
  margin: 0 10px;
  position: absolute;
  top: calc((.5rem + 1px) * -1);
  width: 1rem;
}

.dropdown-menu .popover-arrow::after, .dropdown-menu .popover-arrow::before {
  border-color: transparent;
  border-style: solid;
  content: "";
  display: block;
  position: absolute;
}

.dropdown-menu .popover-arrow::before {
  border-bottom-color: var(--anw-border-color-translucent);
  border-width: 0 0.5rem 0.5rem;
  top: 0;
}

.dropdown-menu .popover-arrow::after {
  border-bottom-color: #fff;
  border-width: 0 0.5rem 0.5rem;
  top: 1px;
}